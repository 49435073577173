// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-contact-us-request-form-index-tsx": () => import("./../../../src/pages/contact-us/request-form/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-request-form-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-services-index-tsx": () => import("./../../../src/pages/our-services/index.tsx" /* webpackChunkName: "component---src-pages-our-services-index-tsx" */),
  "component---src-pages-our-services-services-for-businesses-bespoke-business-services-index-tsx": () => import("./../../../src/pages/our-services/services-for-businesses/bespoke-business-services/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-businesses-bespoke-business-services-index-tsx" */),
  "component---src-pages-our-services-services-for-businesses-business-succession-planning-index-tsx": () => import("./../../../src/pages/our-services/services-for-businesses/business-succession-planning/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-businesses-business-succession-planning-index-tsx" */),
  "component---src-pages-our-services-services-for-businesses-index-tsx": () => import("./../../../src/pages/our-services/services-for-businesses/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-businesses-index-tsx" */),
  "component---src-pages-our-services-services-for-businesses-institutional-cash-management-index-tsx": () => import("./../../../src/pages/our-services/services-for-businesses/institutional-cash-management/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-businesses-institutional-cash-management-index-tsx" */),
  "component---src-pages-our-services-services-for-businesses-retirement-plan-services-index-tsx": () => import("./../../../src/pages/our-services/services-for-businesses/retirement-plan-services/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-businesses-retirement-plan-services-index-tsx" */),
  "component---src-pages-our-services-services-for-individuals-estate-planning-index-tsx": () => import("./../../../src/pages/our-services/services-for-Individuals/estate-planning/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-individuals-estate-planning-index-tsx" */),
  "component---src-pages-our-services-services-for-individuals-index-tsx": () => import("./../../../src/pages/our-services/services-for-Individuals/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-individuals-index-tsx" */),
  "component---src-pages-our-services-services-for-individuals-insurance-and-tax-strategies-index-tsx": () => import("./../../../src/pages/our-services/services-for-Individuals/insurance-and-tax-strategies/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-individuals-insurance-and-tax-strategies-index-tsx" */),
  "component---src-pages-our-services-services-for-individuals-portfolio-management-index-tsx": () => import("./../../../src/pages/our-services/services-for-Individuals/portfolio-management/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-individuals-portfolio-management-index-tsx" */),
  "component---src-pages-our-services-services-for-individuals-socially-responsible-investing-index-tsx": () => import("./../../../src/pages/our-services/services-for-Individuals/socially-responsible-investing/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-individuals-socially-responsible-investing-index-tsx" */),
  "component---src-pages-our-services-services-for-individuals-wealth-planning-services-index-tsx": () => import("./../../../src/pages/our-services/services-for-Individuals/wealth-planning-services/index.tsx" /* webpackChunkName: "component---src-pages-our-services-services-for-individuals-wealth-planning-services-index-tsx" */),
  "component---src-pages-our-specialities-index-tsx": () => import("./../../../src/pages/our-specialities/index.tsx" /* webpackChunkName: "component---src-pages-our-specialities-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-who-we-are-index-tsx": () => import("./../../../src/pages/who-we-are/index.tsx" /* webpackChunkName: "component---src-pages-who-we-are-index-tsx" */)
}

